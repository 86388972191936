import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-session-lock-screen',
  templateUrl: './session-lock-screen.component.html',
  styleUrls: ['./session-lock-screen.component.scss']
})
export class SessionLockScreenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
