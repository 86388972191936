<div class="card-body">
    <div id="apex-chart-datetime-x-axis">
        <div class="toolbar">
            <button id="one_month">1M</button>
            <button id="six_months">6M</button>
            <button id="one_year" class="active">1Y</button>
            <button id="ytd">YTD</button>
            <button id="all">ALL</button>
        </div>
        <div id="timeline-chart"></div>
    </div>
</div>