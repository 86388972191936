import { HttpClient, HttpHeaders } from "@angular/common/http";
import { computed, inject, Injectable, signal, Signal } from "@angular/core";
import { catchError, map, Observable, of, tap, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import {
    AuthStatus,
    CheckTokenResponse,
    LoginResponse,
    User,
} from "../interfaces";
import { ApiResponse } from "src/app/dahsboard/pages/anuncios/interfaces/response.interface";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    private readonly baseUrl: string = environment.baseUrl;
    private http = inject(HttpClient);

    private _currentUser = signal<User | null>(null);
    private _authStatus = signal<AuthStatus>(AuthStatus.checking);

    //publica
    public currentUser = computed(() => this._currentUser());
    public authStatus = computed(() => this._authStatus());

    constructor() {
        this.checkAuthStatus().subscribe();
    }

    private setAuthentication(user: User, token: string): boolean {
        this._currentUser.set(user);
        this._authStatus.set(AuthStatus.authenticated);
        localStorage.setItem("token", token);
        return true;
    }

    // login(email: string, password: string): Observable<boolean> {
    //     const url = `${this.baseUrl}/auth/login`;
    //     const body = { email, password };

    //     return this.http.post<LoginResponse>(url, body).pipe(
    //         tap(({ user, token }) => {
    //             console.log("User logged in");
    //             console.log(user, token);
    //         }),
    //         map(() => this.setAuthentication(user, token)),
    //         catchError((err) => throwError(() => err.error.message))
    //     );
    // }
    login(email: string, password: string): Observable<boolean> {
        const url = `${this.baseUrl}/auth/login`;
        const body = { email, password };

        return this.http.post<ApiResponse<LoginResponse>>(url, body).pipe(
            map((response) => {
                this.setAuthentication(response.data.user, response.data.token);
                return true;
            }),
            catchError((err) => throwError(() => err.error.message))
        );
    }

    checkAuthStatus(): Observable<boolean> {
        const url = `${this.baseUrl}/auth/check-token`;
        const token = localStorage.getItem("token");
        // console.log(token);
        // console.log("mitoken" + token);
        if (!token) {
            this.logout();
            return of(false);
        }

        const headers = new HttpHeaders().set(
            "Authorization",
            `Bearer ${token}`
        );
        return this.http
            .get<ApiResponse<CheckTokenResponse>>(url, { headers })
            .pipe(
                map((response) =>
                    this.setAuthentication(
                        response.data.user,
                        response.data.token
                    )
                ),
                catchError(() => {
                    this._authStatus.set(AuthStatus.notAuthenticated);
                    return of(false);
                })
            );
    }
    logout() {
        // console.log("----------logout");
        this._currentUser.set(null);
        this._authStatus.set(AuthStatus.notAuthenticated);
        localStorage.removeItem("token");
        const token = localStorage.getItem("token");
        // console.log(token);
        // console.log("mitoken cerrar sesion" + token);
    }
}
