<!-- Breadcrumb Area -->
<div class="breadcrumb-area">
    <h1>Dashboard</h1>

    <ol class="breadcrumb">
        <li class="item"><a routeLink="/ui-components/buttons"><i class='bx bx-home-alt'></i></a></li>

        <li class="item">Dashboard</li>

        <li class="item">Buttons</li>
    </ol>
</div>
<!-- End Breadcrumb Area -->

<!-- Start -->
<div class="card mb-30 button-card-box">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Default Buttons</h3>
    </div>

    <div class="card-body">
        <button type="button" class="btn btn-primary mr-2 mt-2">Primary</button>
        <button type="button" class="btn btn-secondary mr-2 mt-2">Secondary</button>
        <button type="button" class="btn btn-success mr-2 mt-2">Success</button>
        <button type="button" class="btn btn-danger mr-2 mt-2">Danger</button>
        <button type="button" class="btn btn-warning mr-2 mt-2">Warning</button>
        <button type="button" class="btn btn-info mr-2 mt-2">Info</button>
        <button type="button" class="btn btn-light mr-2 mt-2">Light</button>
        <button type="button" class="btn btn-dark mr-2 mt-2">Dark</button>
        <button type="button" class="btn btn-link mt-2">Link</button>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="defaultButtons"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-primary"</span><span class="nt">&gt;</span>Primary<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-secondary"</span><span class="nt">&gt;</span>Secondary<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-success"</span><span class="nt">&gt;</span>Success<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-danger"</span><span class="nt">&gt;</span>Danger<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-warning"</span><span class="nt">&gt;</span>Warning<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-info"</span><span class="nt">&gt;</span>Info<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-light"</span><span class="nt">&gt;</span>Light<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-dark"</span><span class="nt">&gt;</span>Dark<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-link"</span><span class="nt">&gt;</span>Link<span class="nt">&lt;/button&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#defaultButtons"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30 button-card-box">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Rounded Buttons</h3>
    </div>

    <div class="card-body">
        <button type="button" class="btn btn-primary rounded-pill mr-2 mt-2">Primary</button>
        <button type="button" class="btn btn-secondary rounded-pill mr-2 mt-2">Secondary</button>
        <button type="button" class="btn btn-success rounded-pill mr-2 mt-2">Success</button>
        <button type="button" class="btn btn-danger rounded-pill mr-2 mt-2">Danger</button>
        <button type="button" class="btn btn-warning rounded-pill mr-2 mt-2">Warning</button>
        <button type="button" class="btn btn-info rounded-pill mr-2 mt-2">Info</button>
        <button type="button" class="btn btn-light rounded-pill mr-2 mt-2">Light</button>
        <button type="button" class="btn btn-dark rounded-pill mr-2 mt-2">Dark</button>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="roundedButtons"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-primary rounded-pill"</span><span class="nt">&gt;</span>Primary<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-secondary rounded-pill"</span><span class="nt">&gt;</span>Secondary<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-success rounded-pill"</span><span class="nt">&gt;</span>Success<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-danger rounded-pill"</span><span class="nt">&gt;</span>Danger<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-warning rounded-pill"</span><span class="nt">&gt;</span>Warning<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-info rounded-pill"</span><span class="nt">&gt;</span>Info<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-light rounded-pill"</span><span class="nt">&gt;</span>Light<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-dark rounded-pill"</span><span class="nt">&gt;</span>Dark<span class="nt">&lt;/button&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#roundedButtons"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30 button-card-box">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Outline Buttons</h3>
    </div>

    <div class="card-body">
        <button type="button" class="btn btn-outline-primary mr-2 mt-2">Primary</button>
        <button type="button" class="btn btn-outline-secondary mr-2 mt-2">Secondary</button>
        <button type="button" class="btn btn-outline-success mr-2 mt-2">Success</button>
        <button type="button" class="btn btn-outline-danger mr-2 mt-2">Danger</button>
        <button type="button" class="btn btn-outline-warning mr-2 mt-2">Warning</button>
        <button type="button" class="btn btn-outline-info mr-2 mt-2">Info</button>
        <button type="button" class="btn btn-outline-light mr-2 mt-2">Light</button>
        <button type="button" class="btn btn-outline-dark mr-2 mt-2">Dark</button>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="outlineButtons"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-outline-primary"</span><span class="nt">&gt;</span>Primary<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-outline-secondary"</span><span class="nt">&gt;</span>Secondary<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-outline-success"</span><span class="nt">&gt;</span>Success<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-outline-danger"</span><span class="nt">&gt;</span>Danger<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-warning"</span><span class="nt">&gt;</span>Warning<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-outline-info"</span><span class="nt">&gt;</span>Info<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-outline-light"</span><span class="nt">&gt;</span>Light<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-outline-dark"</span><span class="nt">&gt;</span>Dark<span class="nt">&lt;/button&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#outlineButtons"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30 button-card-box">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Icon Only Buttons</h3>
    </div>

    <div class="card-body">
        <button type="button" class="btn btn-outline-primary mr-2 mt-2"><i class='bx bx-home-circle'></i></button>
        <button type="button" class="btn btn-outline-secondary mr-2 mt-2"><i class='bx bxs-home-heart'></i></button>
        <button type="button" class="btn btn-outline-success mr-2 mt-2"><i class='bx bx-home-heart'></i></button>
        <button type="button" class="btn btn-outline-danger mr-2 mt-2"><i class='bx bxs-home-circle'></i></button>
        <button type="button" class="btn btn-outline-warning mr-2 mt-2"><i class='bx bxs-home'></i></button>
        <button type="button" class="btn btn-outline-info mr-2 mt-2"><i class='bx bx-home-alt'></i></button>
        <button type="button" class="btn btn-outline-light mr-2 mt-2"><i class='bx bx-home'></i></button>
        <button type="button" class="btn btn-outline-dark mr-2 mt-2"><i class='bx bx-user-pin'></i></button>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="iconOnlyButtons"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-outline-primary"</span><span class="nt">&gt;</span><span class="nt">&lt;i</span> <span class="na">class=</span><span class="s">"bx bx-home-circle"</span><span class="nt">&gt;</span><span class="nt">&lt;/i&gt;</span><span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-outline-secondary"</span><span class="nt">&gt;</span><span class="nt">&lt;i</span> <span class="na">class=</span><span class="s">"bx bx-home-circle"</span><span class="nt">&gt;</span><span class="nt">&lt;/i&gt;</span><span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-outline-success"</span><span class="nt">&gt;</span><span class="nt">&lt;i</span> <span class="na">class=</span><span class="s">"bx bx-home-circle"</span><span class="nt">&gt;</span><span class="nt">&lt;/i&gt;</span><span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-outline-danger"</span><span class="nt">&gt;</span><span class="nt">&lt;i</span> <span class="na">class=</span><span class="s">"bx bx-home-circle"</span><span class="nt">&gt;</span><span class="nt">&lt;/i&gt;</span><span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-outline-warning"</span><span class="nt">&gt;</span><span class="nt">&lt;i</span> <span class="na">class=</span><span class="s">"bx bx-home-circle"</span><span class="nt">&gt;</span><span class="nt">&lt;/i&gt;</span><span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-outline-info"</span><span class="nt">&gt;</span><span class="nt">&lt;i</span> <span class="na">class=</span><span class="s">"bx bx-home-circle"</span><span class="nt">&gt;</span><span class="nt">&lt;/i&gt;</span><span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-outline-light"</span><span class="nt">&gt;</span><span class="nt">&lt;i</span> <span class="na">class=</span><span class="s">"bx bx-home-circle"</span><span class="nt">&gt;</span><span class="nt">&lt;/i&gt;</span><span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-outline-dark"</span><span class="nt">&gt;</span><span class="nt">&lt;i</span> <span class="na">class=</span><span class="s">"bx bx-home-circle"</span><span class="nt">&gt;</span><span class="nt">&lt;/i&gt;</span><span class="nt">&lt;/button&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#iconOnlyButtons"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30 button-card-box">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Buttons Sizes</h3>
    </div>

    <div class="card-body">
        <h6 class="mt-2 mb-1 font-weight-bold">Large Button</h6>
        <button type="button" class="btn btn-lg btn-primary mr-2 mt-2">Primary</button>
        <button type="button" class="btn btn-lg btn-secondary mr-2 mt-2">Secondary</button>
        <button type="button" class="btn btn-lg btn-success mr-2 mt-2">Success</button>
        <button type="button" class="btn btn-lg btn-danger mr-2 mt-2">Danger</button>
        <button type="button" class="btn btn-lg btn-warning mr-2 mt-2">Warning</button>
        <button type="button" class="btn btn-lg btn-info mr-2 mt-2">Info</button>
        <button type="button" class="btn btn-lg btn-light mr-2 mt-2">Light</button>
        <button type="button" class="btn btn-lg btn-dark mr-2 mt-2">Dark</button>
        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="buttonsSizes"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-primary btn-lg"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-secondary btn-lg"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-success btn-lg"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-danger btn-lg"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-warning btn-lg"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-info btn-lg"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-light btn-lg"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-dark btn-lg"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#buttonsSizes"><i class='bx bx-copy'></i></button>
            </div>
        </div>
        <h6 class="mt-4 mb-1 font-weight-bold">Medium Button</h6>
        <button type="button" class="btn btn-md btn-primary mr-2 mt-2">Primary</button>
        <button type="button" class="btn btn-md btn-secondary mr-2 mt-2">Secondary</button>
        <button type="button" class="btn btn-md btn-success mr-2 mt-2">Success</button>
        <button type="button" class="btn btn-md btn-danger mr-2 mt-2">Danger</button>
        <button type="button" class="btn btn-md btn-warning mr-2 mt-2">Warning</button>
        <button type="button" class="btn btn-md btn-info mr-2 mt-2">Info</button>
        <button type="button" class="btn btn-md btn-light mr-2 mt-2">Light</button>
        <button type="button" class="btn btn-md btn-dark mr-2 mt-2">Dark</button>
        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="buttonsSizesMedium"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-primary btn-md"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-secondary btn-md"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-success btn-md"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-danger btn-md"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-warning btn-md"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-info btn-md"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-light btn-md"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-dark btn-md"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#buttonsSizesMedium"><i class='bx bx-copy'></i></button>
            </div>
        </div>
        <h6 class="mt-4 mb-1 font-weight-bold">Small Button</h6>
        <button type="button" class="btn btn-sm btn-primary mr-2 mt-2">Primary</button>
        <button type="button" class="btn btn-sm btn-secondary mr-2 mt-2">Secondary</button>
        <button type="button" class="btn btn-sm btn-success mr-2 mt-2">Success</button>
        <button type="button" class="btn btn-sm btn-danger mr-2 mt-2">Danger</button>
        <button type="button" class="btn btn-sm btn-warning mr-2 mt-2">Warning</button>
        <button type="button" class="btn btn-sm btn-info mr-2 mt-2">Info</button>
        <button type="button" class="btn btn-sm btn-light mr-2 mt-2">Light</button>
        <button type="button" class="btn btn-sm btn-dark mr-2 mt-2">Dark</button>
        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="buttonsSizesSmall"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-primary btn-sm"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-secondary btn-sm"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-success btn-sm"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-danger btn-sm"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-warning btn-sm"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-info btn-sm"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-light btn-sm"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-dark btn-sm"</span><span class="nt">&gt;</span>Large button<span class="nt">&lt;/button&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#buttonsSizesSmall"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30 button-card-box">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Active State Buttons</h3>
    </div>

    <div class="card-body">
        <a href="javascript:void(0)" class="btn btn-primary active mt-2 mr-2" role="button">Primary link</a>
        <a href="javascript:void(0)" class="btn btn-secondary active mt-2 mr-2" role="button">Secondary Link</a>
        <a href="javascript:void(0)" class="btn btn-success active mt-2 mr-2" role="button">Success Link</a>
        <a href="javascript:void(0)" class="btn btn-danger active mt-2 mr-2" role="button">Danger Link</a>
        <a href="javascript:void(0)" class="btn btn-warning active mt-2 mr-2" role="button">Warning Link</a>
        <a href="javascript:void(0)" class="btn btn-info active mt-2 mr-2" role="button">Info Link</a>
        <a href="javascript:void(0)" class="btn btn-light active mt-2 mr-2" role="button">Light Link</a>
        <a href="javascript:void(0)" class="btn btn-dark active mt-2 mr-2" role="button">Dark Link</a>
        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="activeStateButtons"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;a</span> <span class="na">href=</span><span class="s">"#"</span> <span class="na">class=</span><span class="s">"btn btn-primary active"</span> <span class="na">role=</span><span class="s">"button"</span><span class="nt">&gt;</span>Primary link<span class="nt">&lt;/a&gt;</span>
<span class="nt">&lt;a</span> <span class="na">href=</span><span class="s">"#"</span> <span class="na">class=</span><span class="s">"btn btn-secondary active"</span> <span class="na">role=</span><span class="s">"button"</span><span class="nt">&gt;</span>Secondary link<span class="nt">&lt;/a&gt;</span>
<span class="nt">&lt;a</span> <span class="na">href=</span><span class="s">"#"</span> <span class="na">class=</span><span class="s">"btn btn-success active"</span> <span class="na">role=</span><span class="s">"button"</span><span class="nt">&gt;</span>Success link<span class="nt">&lt;/a&gt;</span>
<span class="nt">&lt;a</span> <span class="na">href=</span><span class="s">"#"</span> <span class="na">class=</span><span class="s">"btn btn-danger active"</span> <span class="na">role=</span><span class="s">"button"</span><span class="nt">&gt;</span>Danger link<span class="nt">&lt;/a&gt;</span>
<span class="nt">&lt;a</span> <span class="na">href=</span><span class="s">"#"</span> <span class="na">class=</span><span class="s">"btn btn-warning active"</span> <span class="na">role=</span><span class="s">"button"</span><span class="nt">&gt;</span>Warning link<span class="nt">&lt;/a&gt;</span>
<span class="nt">&lt;a</span> <span class="na">href=</span><span class="s">"#"</span> <span class="na">class=</span><span class="s">"btn btn-info active"</span> <span class="na">role=</span><span class="s">"button"</span><span class="nt">&gt;</span>Info link<span class="nt">&lt;/a&gt;</span>
<span class="nt">&lt;a</span> <span class="na">href=</span><span class="s">"#"</span> <span class="na">class=</span><span class="s">"btn btn-light active"</span> <span class="na">role=</span><span class="s">"button"</span><span class="nt">&gt;</span>Light link<span class="nt">&lt;/a&gt;</span>
<span class="nt">&lt;a</span> <span class="na">href=</span><span class="s">"#"</span> <span class="na">class=</span><span class="s">"btn btn-dark active"</span> <span class="na">role=</span><span class="s">"button"</span><span class="nt">&gt;</span>Dark link<span class="nt">&lt;/a&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#activeStateButtons"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30 button-card-box">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Disabled State Buttons</h3>
    </div>

    <div class="card-body">
        <button type="button" class="btn btn-primary mt-2 mr-2" disabled>Primary button</button>
        <button type="button" class="btn btn-secondary mt-2 mr-2" disabled>Secondary Button</button>
        <button type="button" class="btn btn-success mt-2 mr-2" disabled>Success Button</button>
        <button type="button" class="btn btn-danger mt-2 mr-2" disabled>Danger Button</button>
        <button type="button" class="btn btn-warning mt-2 mr-2" disabled>Warning Button</button>
        <button type="button" class="btn btn-info mt-2 mr-2" disabled>Info Button</button>
        <button type="button" class="btn btn-light mt-2 mr-2" disabled>Light Button</button>
        <button type="button" class="btn btn-dark mt-2" disabled>Dark Button</button>
        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="disabledStateButtons"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-primary"</span> <span class="na">disabled</span><span class="nt">&gt;</span>Primary button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-secondary"</span> <span class="na">disabled</span><span class="nt">&gt;</span>Secondary Button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-success"</span> <span class="na">disabled</span><span class="nt">&gt;</span>Success Button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-danger"</span> <span class="na">disabled</span><span class="nt">&gt;</span>Danger Button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-warning"</span> <span class="na">disabled</span><span class="nt">&gt;</span>Warning Button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-info"</span> <span class="na">disabled</span><span class="nt">&gt;</span>Info Button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-light"</span> <span class="na">disabled</span><span class="nt">&gt;</span>Light Button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-dark"</span> <span class="na">disabled</span><span class="nt">&gt;</span>Dark Button<span class="nt">&lt;/button&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#disabledStateButtons"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30 button-card-box">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Checkbox and Radio Buttons</h3>
    </div>

    <div class="card-body">
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label class="btn btn-secondary active">
                <input type="radio" name="options" id="option1" checked> Active
            </label>
            <label class="btn btn-secondary">
            <input type="radio" name="options" id="option2"> Radio
                </label>
            <label class="btn btn-secondary">
                <input type="radio" name="options" id="option3"> Radio
            </label>
        </div>
        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="checkboxRadioButtons"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"btn-group btn-group-toggle"</span> <span class="na">data-toggle=</span><span class="s">"buttons"</span><span class="nt">&gt;</span>
<span class="nt">&lt;label</span> <span class="na">class=</span><span class="s">"btn btn-secondary active"</span><span class="nt">&gt;</span>
<span class="nt">&lt;input</span> <span class="na">type=</span><span class="s">"radio"</span> <span class="na">name=</span><span class="s">"options"</span> <span class="na">id=</span><span class="s">"option1"</span> <span class="na">checked</span><span class="nt">&gt;</span> Active
<span class="nt">&lt;/label&gt;</span>
<span class="nt">&lt;label</span> <span class="na">class=</span><span class="s">"btn btn-secondary"</span><span class="nt">&gt;</span>
<span class="nt">&lt;input</span> <span class="na">type=</span><span class="s">"radio"</span> <span class="na">name=</span><span class="s">"options"</span> <span class="na">id=</span><span class="s">"option2"</span><span class="nt">&gt;</span> Radio
<span class="nt">&lt;/label&gt;</span>
<span class="nt">&lt;label</span> <span class="na">class=</span><span class="s">"btn btn-secondary"</span><span class="nt">&gt;</span>
<span class="nt">&lt;input</span> <span class="na">type=</span><span class="s">"radio"</span> <span class="na">name=</span><span class="s">"options"</span> <span class="na">id=</span><span class="s">"option3"</span><span class="nt">&gt;</span> Radio
<span class="nt">&lt;/label&gt;</span>
<span class="nt">&lt;/div&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#checkboxRadioButtons"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30 button-card-box">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Display Block Buttons</h3>
    </div>

    <div class="card-body">
        <button type="button" class="btn btn-primary mt-2 btn-lg btn-block">Block level button</button>
        <button type="button" class="btn btn-secondary mt-2 btn-lg btn-block">Block level button</button>
        <button type="button" class="btn btn-success mt-2 btn-lg btn-block">Block level button</button>
        <button type="button" class="btn btn-danger mt-2 btn-lg btn-block">Block level button</button>
        <button type="button" class="btn btn-warning mt-2 btn-lg btn-block">Block level button</button>
        <button type="button" class="btn btn-info mt-2 btn-lg btn-block">Block level button</button>
        <button type="button" class="btn btn-light mt-2 btn-lg btn-block">Block level button</button>
        <button type="button" class="btn btn-dark mt-2 btn-lg btn-block">Block level button</button>
        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="displayBlockButtons"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-primary btn-lg btn-block"</span><span class="nt">&gt;</span>Block level button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-secondary btn-lg btn-block"</span><span class="nt">&gt;</span>Block level button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-success btn-lg btn-block"</span><span class="nt">&gt;</span>Block level button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-danger btn-lg btn-block"</span><span class="nt">&gt;</span>Block level button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-warning btn-lg btn-block"</span><span class="nt">&gt;</span>Block level button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-info btn-lg btn-block"</span><span class="nt">&gt;</span>Block level button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-light btn-lg btn-block"</span><span class="nt">&gt;</span>Block level button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-dark btn-lg btn-block"</span><span class="nt">&gt;</span>Block level button<span class="nt">&lt;/button&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#displayBlockButtons"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>
<!-- End -->