<!-- Breadcrumb Area -->
<div class="breadcrumb-area">
    <h1>Dashboard</h1>

    <ol class="breadcrumb">
        <li class="item"><a routerLink="/timeline"><i class='bx bx-home-alt'></i></a></li>

        <li class="item">Dashboard</li>

        <li class="item">Timeline</li>
    </ol>
</div>
<!-- End Breadcrumb Area -->

<!-- Start Timeline Area -->
<div class="timeline mb-30">
    <div class="timeline-month">
        January, 2020
    </div>

    <div class="timeline-section">
        <div class="timeline-date">
            21, Tuesday
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="timeline-box">
                    <div class="box-title">
                        <i class='bx bxs-florist'></i> Job Created
                    </div>

                    <div class="box-content">
                        <div class="box-item"><p class="mb-0">Dolorum provident rerum aut hic quasi placeat iure tempora laudantium ipsa ad debitis unde?</p></div>
                        <div class="box-item"><strong>Loss Type</strong>: A/C Leak</div>
                        <div class="box-item"><strong>Loss Territory</strong>: Texas</div>
                        <div class="box-item"><strong>Start Date</strong>: 08/22/2018</div>
                    </div>

                    <div class="box-footer">- Andro Smith</div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="timeline-box">
                    <div class="box-title">
                        <i class='bx bx-edit'></i> Job Edited
                    </div>

                    <div class="box-content">
                        <div class="box-item"><strong>Project Manager</strong>: Marlyn</div>
                        <div class="box-item"><strong>Supervisor</strong>: Carol</div>
                    </div>

                    <div class="box-footer">- Andro Smith</div>
                </div>
            </div>
        </div>
    </div>

    <div class="timeline-section">
        <div class="timeline-date">
            22, Wednesday
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="timeline-box">
                    <div class="box-title">
                        <i class='bx bx-edit'></i> Job Edited
                    </div>

                    <div class="box-content">
                        <div class="box-item"><strong>Extraction Type</strong>: Carpet Heavy</div>
                        <div class="box-item"><strong>Water Category</strong>: 4</div>
                        <div class="box-item"><strong>No. Of Tech</strong>: 6</div>
                        <div class="box-item"><strong>Est. Complation</strong>: 09/01/2018</div>
                    </div>
                    
                    <div class="box-footer">- Andro Smith</div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="timeline-box">
                    <div class="box-title">
                        <i class='bx bx-task'></i> New Job To Do
                    </div>

                    <div class="box-content">
                        <div class="box-item"><strong>Employee</strong>: Sam</div>
                        <div class="box-item"><strong>To Do</strong>: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nisi nulla, viverra quis felis sit amet, lacinia feugiat odio. Aliquam sed orci elementum, volutpat dolor eget, venenatis nunc</div>
                    </div>

                    <div class="box-footer">- Andro Smith</div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="timeline-box">
                    <div class="box-title">
                        <i class='bx bx-task'></i> New Job To Do
                    </div>

                    <div class="box-content">
                        <div class="box-item"><strong>Employee</strong>: Jones</div>
                        <div class="box-item"><strong>To Do</strong>: Proin sit amet aliquet neque, eget sagittis nunc. Proin convallis lectus quis volutpat pharetra. Donec quis ultrices eros. Ut eget mi faucibus.</div>
                    </div>

                    <div class="box-footer">- Andro Smith</div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="timeline-box">
                    <div class="box-title">
                        <i class='bx bxs-thermometer'></i> Pschrometrics
                    </div>

                    <div class="box-content">
                        <div class="box-item"><strong>Temp.</strong>: 23 <sup>o</sup>C</div>
                        <div class="box-item"><strong>Rh </strong>: 42</div>
                        <div class="box-item"><strong>Comments</strong>: Integer nec placerat ipsum. Aliquam id ligula suscipit, ornare dui nec, laoreet tortor.</div>
                    </div>

                    <div class="box-footer">- Andro Smith</div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="timeline-box">
                    <div class="box-title">
                        <i class='bx bx-building'></i> Room Created
                    </div>

                    <div class="box-content">
                        <div class="box-item"><strong>Name</strong>: Kitchen</div>
                        <div class="box-item"><strong>Floor Level </strong>: 2</div>
                        <div class="box-item"><strong>Dimensions</strong>: 26 x 11 x 8</div>
                    </div>

                    <div class="box-footer">- Andro Smith</div>
                </div>
            </div>
        </div>
    </div>

    <div class="timeline-section">
        <div class="timeline-date">
            23, Thursday
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="timeline-box">
                    <div class="box-title">
                        <i class='bx bx-task'></i> Job To Do Completed
                    </div>

                    <div class="box-content">
                        <div class="box-item"><strong>Employee</strong>: Sam</div>
                        <div class="box-item"><strong>Employee Response</strong>: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nisi nulla, viverra quis felis sit amet, lacinia feugiat odio. Aliquam sed orci elementum, volutpat dolor eget, venenatis nunc</div>
                    </div>

                    <div class="box-footer">- Andro Smith</div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="timeline-box">
                    <div class="box-title">
                        <i class='bx bx-cog'></i> Equipment Entry
                    </div>

                    <div class="box-content">
                        <div class="box-item"><strong>ID</strong>: TW-3232</div>
                        <div class="box-item"><strong>Name</strong>: HEPA 600</div>
                        <div class="box-item"><strong>Date In</strong>: 08/22/2018</div>
                    </div>

                    <div class="box-footer">- Andro Smith</div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Timeline Area -->