import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-borders',
  templateUrl: './borders.component.html',
  styleUrls: ['./borders.component.scss']
})
export class BordersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
