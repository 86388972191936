import { inject } from "@angular/core";
import { Router, type CanActivateFn } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { AuthStatus } from "../interfaces";

export const isAuthenticatedGuard: CanActivateFn = (route, state) => {
    // const url = state.url;
    // localStorage.setItem('url', url);

    const authService = inject(AuthService);
    const router = inject(Router);
    // console.log({
    //     status: authService.authStatus(),
    // });
    // console.log("isAuthenticatedGuard", route, state);

    if (authService.authStatus() === AuthStatus.authenticated) {
        console.log("isNotAuthenticatedGuard ingreso al if", route, state);
        return true;
    }
    if (authService.authStatus() === AuthStatus.checking) {
        // console.log(
        //     "isNotAuthenticatedGuard ingreso al if checking",
        //     route,
        //     state
        // );

        return false;
    }
    // console.log("isAuthenticatedGuard ingreso a ningun if", route, state);

    router.navigateByUrl("/auth/login");

    return false;
};
