<!-- Breadcrumb Area -->
<div class="breadcrumb-area">
    <h1>Dashboard</h1>

    <ol class="breadcrumb">
        <li class="item"><a routerLink="/card/basic-card"><i class='bx bx-home-alt'></i></a></li>

        <li class="item">Dashboard</li>

        <li class="item">Basic Card</li>
    </ol>
</div>
<!-- End Breadcrumb Area -->

<!-- Start -->
<div class="row">
    <div class="col-lg-4 col-md-6">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Card With Header And Footer</h3>
            </div>

            <div class="card-body">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>

                <img src="assets/img/1.jpg" alt="image">
            </div>

            <div class="card-footer">
                <small class="text-muted">Last updated 3 mins ago</small>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Carousel</h3>
            </div>

            <div class="card-body">        
                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="d-block w-100" src="assets/img/2.jpg" alt="First slide">
                        </div>

                        <div class="carousel-item">
                            <img class="d-block w-100" src="assets/img/3.jpg" alt="First slide">
                        </div>

                        <div class="carousel-item">
                            <img class="d-block w-100" src="assets/img/4.jpg" alt="First slide">
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
                
                <p class="mb-2 mt-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>

                <small class="text-muted">Last updated 3 mins ago</small>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card mb-30 p-0">
            <div class="card-body">
                <img src="assets/img/5.jpg" alt="image">
            </div>

            <div class="card-body p-4">
                <p class="mb-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>

                <small class="text-muted">Last updated 3 mins ago</small>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-6 col-md-12">
        <div class="card bg-dark text-white mb-30 p-0">
            <img src="assets/img/slider/1.jpg" class="card-img" alt="image">

            <div class="card-img-overlay">
                <h5 class="card-title text-white font-weight-bold">Card title</h5>
                <p class="card-text mb-2">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                <small>Last updated 3 mins ago</small>
            </div>
        </div>
    </div>

    <div class="col-lg-6 col-md-12">
        <div class="card mb-30 p-0">
            <div class="card-body">
                <div class="youtube-video">
                    <iframe src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

<h4 class="mb-3 mt-3 font-weight-bold">Card Groups</h4>
<div class="card-group mb-30">
    <div class="card p-0">
        <img src="assets/img/1.jpg" class="card-img-top" alt="image">
        <div class="card-body p-4">
            <h5 class="card-title font-weight-bold">Card Title</h5>
            <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
        </div>
    </div>

    <div class="card p-0">
        <img src="assets/img/2.jpg" class="card-img-top" alt="image">
        <div class="card-body p-4">
            <h5 class="card-title font-weight-bold">Card Title</h5>
            <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
        </div>
    </div>

    <div class="card p-0">
        <img src="assets/img/3.jpg" class="card-img-top" alt="image">
        <div class="card-body p-4">
            <h5 class="card-title font-weight-bold">Card Title</h5>
            <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
        </div>
    </div>
</div>

<h4 class="mb-3 mt-3 font-weight-bold">Card Decks</h4>
<div class="card-deck mb-30">
    <div class="card p-0">
        <img src="assets/img/6.jpg" class="card-img-top" alt="image">
        <div class="card-body p-4">
            <h5 class="card-title font-weight-bold">Card title</h5>
            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
        </div>
    </div>

    <div class="card p-0">
        <img src="assets/img/5.jpg" class="card-img-top" alt="image">
        <div class="card-body p-4">
            <h5 class="card-title font-weight-bold">Card title</h5>
            <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
        </div>
    </div>

    <div class="card p-0">
        <img src="assets/img/4.jpg" class="card-img-top" alt="image">
        <div class="card-body p-4">
            <h5 class="card-title font-weight-bold">Card title</h5>
            <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
        </div>
    </div>
</div>

<h4 class="mb-3 mt-3 font-weight-bold">Card Columns</h4>
<div class="card-columns mb-30">
    <div class="card p-0">
        <img src="assets/img/1.jpg" class="card-img-top" alt="image">
        <div class="card-body p-4">
            <h5 class="card-title font-weight-bold">Card title that wraps to a new line</h5>
            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
        </div>
    </div>

    <div class="card">
        <blockquote class="blockquote mb-0 card-body">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
            <footer class="blockquote-footer">
            <small class="text-muted">
                Someone famous in <cite title="Source Title">Source Title</cite>
            </small>
            </footer>
        </blockquote>
    </div>

    <div class="card bg-success text-white text-center">
        <blockquote class="blockquote mb-0">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat.</p>
            <footer class="blockquote-footer text-white">
                <small>
                    Someone famous in <cite title="Source Title">Source Title</cite>
                </small>
            </footer>
        </blockquote>
    </div>

    <div class="card text-center">
        <div class="card-body">
            <h5 class="card-title font-weight-bold">Card title</h5>
            <p class="card-text">This card has a regular title and short paragraphy of text below it.</p>
            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
        </div>
    </div>

    <div class="card p-0">
        <img src="assets/img/3.jpg" class="card-img-top" alt="image">
    </div>

    <div class="card text-end">
        <blockquote class="blockquote mb-0">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
            <footer class="blockquote-footer">
                <small class="text-muted">
                    Someone famous in <cite title="Source Title">Source Title</cite>
                </small>
            </footer>
        </blockquote>
    </div>

    <div class="card">
        <div class="card-body">
            <h5 class="card-title font-weight-bold">Card title</h5>
            <p class="card-text">This is another card with title and supporting text below. This card has some additional content to make it slightly taller overall.</p>
            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
        </div>
    </div>

    <div class="card p-0">
        <img src="assets/img/2.jpg" class="card-img-top" alt="image">

        <div class="card-body p-4">
            <h5 class="card-title font-weight-bold">Card title</h5>
            <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
        </div>
    </div>
</div>
<!-- End -->