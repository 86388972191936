import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-authorized-with-image',
  templateUrl: './not-authorized-with-image.component.html',
  styleUrls: ['./not-authorized-with-image.component.scss']
})
export class NotAuthorizedWithImageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
