import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vertical-alignment',
  templateUrl: './vertical-alignment.component.html',
  styleUrls: ['./vertical-alignment.component.scss']
})
export class VerticalAlignmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
