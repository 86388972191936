import { Component, OnInit } from "@angular/core";
declare let $: any;

@Component({
    selector: "app-analytics",
    templateUrl: "./analytics.component.html",
    styleUrls: ["./analytics.component.scss"]
})
export class AnalyticsComponent implements OnInit {
    constructor() {}

    ngOnInit() {
        
    }
}
