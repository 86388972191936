import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-boxicons',
  templateUrl: './boxicons.component.html',
  styleUrls: ['./boxicons.component.scss']
})
export class BoxiconsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
