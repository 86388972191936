<!-- Breadcrumb Area -->
<div class="breadcrumb-area">
    <h1>Dashboard</h1>

    <ol class="breadcrumb">
        <li class="item"><a routerLink="/ui-components/spinner"><i class='bx bx-home-alt'></i></a></li>

        <li class="item">Dashboard</li>

        <li class="item">Spinners</li>
    </ol>
</div>
<!-- End Breadcrumb Area -->

<!-- Start -->
<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Border Spinner</h3>

        <div class="dropdown">
            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class='bx bx-dots-horizontal-rounded' ></i>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-show'></i> View
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-edit-alt'></i> Edit
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-trash'></i> Delete
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-printer'></i> Print
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-download'></i> Download
                </a>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="borderSpinner"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-border"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#borderSpinner"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Colors Spinner</h3>

        <div class="dropdown">
            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class='bx bx-dots-horizontal-rounded' ></i>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-show'></i> View
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-edit-alt'></i> Edit
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-trash'></i> Delete
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-printer'></i> Print
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-download'></i> Download
                </a>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-border text-danger" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-border text-info" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-border text-light" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-border text-dark" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="colorSpinner"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-border text-primary"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-border text-secondary"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-border text-success"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-border text-danger"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-border text-warning"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-border text-info"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-border text-light"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-border text-dark"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#colorSpinner"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Growing Spinner</h3>

        <div class="dropdown">
            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class='bx bx-dots-horizontal-rounded' ></i>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-show'></i> View
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-edit-alt'></i> Edit
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-trash'></i> Delete
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-printer'></i> Print
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-download'></i> Download
                </a>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="spinner-grow text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow text-success" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow text-danger" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow text-info" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow text-light" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow text-dark" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="growingSpinner"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-grow text-primary"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-grow text-secondary"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-grow text-success"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-grow text-danger"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-grow text-warning"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-grow text-info"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-grow text-light"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-grow text-dark"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#growingSpinner"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Alignment Margin</h3>

        <div class="dropdown">
            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class='bx bx-dots-horizontal-rounded' ></i>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-show'></i> View
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-edit-alt'></i> Edit
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-trash'></i> Delete
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-printer'></i> Print
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-download'></i> Download
                </a>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="spinner-border m-5" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="alignmentMarginSpinners"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-border m-5"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#alignmentMarginSpinners"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Placement Flex</h3>

        <div class="dropdown">
            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class='bx bx-dots-horizontal-rounded' ></i>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-show'></i> View
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-edit-alt'></i> Edit
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-trash'></i> Delete
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-printer'></i> Print
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-download'></i> Download
                </a>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="placementFloats2"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"d-flex justify-content-center"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-border"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
        <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
    <span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;/div&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#placementFloats2"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Placement Flex</h3>

        <div class="dropdown">
            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class='bx bx-dots-horizontal-rounded' ></i>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-show'></i> View
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-edit-alt'></i> Edit
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-trash'></i> Delete
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-printer'></i> Print
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-download'></i> Download
                </a>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="d-flex align-items-center">
            <strong>Loading...</strong>
            <div class="spinner-grow ml-auto" role="status" aria-hidden="true"></div>
        </div>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="placementFlex"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"d-flex align-items-center"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;strong&gt;</span>Loading...<span class="nt">&lt;/strong&gt;</span>
    <span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-grow ml-auto"</span> <span class="na">role=</span><span class="s">"status"</span> <span class="na">aria-hidden=</span><span class="s">"true"</span><span class="nt">&gt;&lt;/div&gt;</span>
<span class="nt">&lt;/div&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#placementFlex"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Placement Floats</h3>

        <div class="dropdown">
            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class='bx bx-dots-horizontal-rounded' ></i>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-show'></i> View
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-edit-alt'></i> Edit
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-trash'></i> Delete
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-printer'></i> Print
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-download'></i> Download
                </a>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="clearfix">
            <div class="spinner-border float-right" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="placementFloats"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"clearfix"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-border float-right"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
        <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
    <span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;/div&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#placementFloats"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Placement Text Align</h3>

        <div class="dropdown">
            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class='bx bx-dots-horizontal-rounded' ></i>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-show'></i> View
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-edit-alt'></i> Edit
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-trash'></i> Delete
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-printer'></i> Print
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-download'></i> Download
                </a>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="placementTextAlign"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"text-center"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-border"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
        <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
    <span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;/div&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#placementTextAlign"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Size</h3>

        <div class="dropdown">
            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class='bx bx-dots-horizontal-rounded' ></i>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-show'></i> View
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-edit-alt'></i> Edit
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-trash'></i> Delete
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-printer'></i> Print
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-download'></i> Download
                </a>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="spinnersSize"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-border spinner-border-sm"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span>
<span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"spinner-grow spinner-grow-sm"</span> <span class="na">role=</span><span class="s">"status"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/div&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#spinnersSize"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30 spinners-card-box">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Buttons</h3>

        <div class="dropdown">
            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class='bx bx-dots-horizontal-rounded' ></i>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-show'></i> View
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-edit-alt'></i> Edit
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-trash'></i> Delete
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-printer'></i> Print
                </a>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                    <i class='bx bx-download'></i> Download
                </a>
            </div>
        </div>
    </div>

    <div class="card-body">
        <button class="btn btn-primary mt-2 mr-2" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="visually-hidden">Loading...</span>
        </button>
        <button class="btn btn-primary mt-2" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
        </button>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="buttonsSpinners"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;button</span> <span class="na">class=</span><span class="s">"btn btn-primary"</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">disabled</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"spinner-border spinner-border-sm"</span> <span class="na">role=</span><span class="s">"status"</span> <span class="na">aria-hidden=</span><span class="s">"true"</span><span class="nt">&gt;&lt;/span&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"visually-hidden"</span><span class="nt">&gt;</span>Loading...<span class="nt">&lt;/span&gt;</span>
<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;button</span> <span class="na">class=</span><span class="s">"btn btn-primary"</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">disabled</span><span class="nt">&gt;</span>
    <span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"spinner-border spinner-border-sm"</span> <span class="na">role=</span><span class="s">"status"</span> <span class="na">aria-hidden=</span><span class="s">"true"</span><span class="nt">&gt;&lt;/span&gt;</span>
    Loading...
<span class="nt">&lt;/button&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#buttonsSpinners"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>
<!-- End -->