import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.scss']
})
export class EmbedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
