<div *ngIf="!finishedAuthCheck()" class="loading-container">
  <img src="assets/img/logo2.png" alt="Loading Image" class="loading-image">
  <div class="spinner-container">
    <div class="spinner-border spinner-border-sm" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow spinner-grow-sm" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>
<router-outlet *ngIf="finishedAuthCheck()" />
