<!-- Breadcrumb Area -->
<div class="breadcrumb-area">
    <h1>Dashboard</h1>

    <ol class="breadcrumb">
        <li class="item"><a routerLink="/ui-components/borders"><i class='bx bx-home-alt'></i></a></li>

        <li class="item">Dashboard</li>

        <li class="item">Borders</li>
    </ol>
</div>
<!-- End Breadcrumb Area -->

<!-- Start -->
<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Additive</h3>
    </div>

    <div class="card-body">
        <div class="bd-example-border-utils">
            <span class="border"></span>
            <span class="border-top"></span>
            <span class="border-right"></span>
            <span class="border-bottom"></span>
            <span class="border-left"></span>
        </div>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="basicAdditiveBorder"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border"</span><span class="nt">&gt;&lt;/span&gt;</span>
<span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border-top"</span><span class="nt">&gt;&lt;/span&gt;</span>
<span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border-right"</span><span class="nt">&gt;&lt;/span&gt;</span>
<span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border-bottom"</span><span class="nt">&gt;&lt;/span&gt;</span>
<span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border-left"</span><span class="nt">&gt;&lt;/span&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#basicAdditiveBorder"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Subtractive</h3>
    </div>

    <div class="card-body">
        <div class="bd-example-border-utils bd-example-border-utils-0">
            <span class="border-0"></span>
            <span class="border-top-0"></span>
            <span class="border-right-0"></span>
            <span class="border-bottom-0"></span>
            <span class="border-left-0"></span>
        </div>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="basicSubtractiveBorder"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border-0"</span><span class="nt">&gt;&lt;/span&gt;</span>
<span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border-top-0"</span><span class="nt">&gt;&lt;/span&gt;</span>
<span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border-right-0"</span><span class="nt">&gt;&lt;/span&gt;</span>
<span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border-bottom-0"</span><span class="nt">&gt;&lt;/span&gt;</span>
<span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border-left-0"</span><span class="nt">&gt;&lt;/span&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#basicSubtractiveBorder"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Border Color</h3>
    </div>

    <div class="card-body">
        <div class="bd-example-border-utils">
            <span class="border border-primary"></span>
            <span class="border border-secondary"></span>
            <span class="border border-success"></span>
            <span class="border border-danger"></span>
            <span class="border border-warning"></span>
            <span class="border border-info"></span>
            <span class="border border-light"></span>
            <span class="border border-dark"></span>
            <span class="border border-white"></span>
        </div>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="basicBorderColor"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border border-primary"</span><span class="nt">&gt;&lt;/span&gt;</span>
<span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border border-secondary"</span><span class="nt">&gt;&lt;/span&gt;</span>
<span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border border-success"</span><span class="nt">&gt;&lt;/span&gt;</span>
<span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border border-danger"</span><span class="nt">&gt;&lt;/span&gt;</span>
<span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border border-warning"</span><span class="nt">&gt;&lt;/span&gt;</span>
<span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border border-info"</span><span class="nt">&gt;&lt;/span&gt;</span>
<span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border border-light"</span><span class="nt">&gt;&lt;/span&gt;</span>
<span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border border-dark"</span><span class="nt">&gt;&lt;/span&gt;</span>
<span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"border border-white"</span><span class="nt">&gt;&lt;/span&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#basicBorderColor"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Border-radius</h3>
    </div>

    <div class="card-body">
        <div class="bd-example-border-utils">
            <img src="assets/img/70x70.jpg" alt="..." class="rounded">
            <img src="assets/img/70x70.jpg" alt="..." class="rounded-top">
            <img src="assets/img/70x70.jpg" alt="..." class="rounded-right">
            <img src="assets/img/70x70.jpg" alt="..." class="rounded-bottom">
            <img src="assets/img/70x70.jpg" alt="..." class="rounded-left">
            <img src="assets/img/70x70.jpg" alt="..." class="rounded-circle">
            <img src="assets/img/150x75.jpg" alt="..." class="rounded-pill">
            <img src="assets/img/70x70.jpg" alt="..." class="rounded-0">
        </div>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="basicBorderRadius"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;img</span> <span class="na">src=</span><span class="s">"..."</span> <span class="na">alt=</span><span class="s">"..."</span> <span class="na">class=</span><span class="s">"rounded"</span><span class="nt">&gt;</span>
<span class="nt">&lt;img</span> <span class="na">src=</span><span class="s">"..."</span> <span class="na">alt=</span><span class="s">"..."</span> <span class="na">class=</span><span class="s">"rounded-top"</span><span class="nt">&gt;</span>
<span class="nt">&lt;img</span> <span class="na">src=</span><span class="s">"..."</span> <span class="na">alt=</span><span class="s">"..."</span> <span class="na">class=</span><span class="s">"rounded-right"</span><span class="nt">&gt;</span>
<span class="nt">&lt;img</span> <span class="na">src=</span><span class="s">"..."</span> <span class="na">alt=</span><span class="s">"..."</span> <span class="na">class=</span><span class="s">"rounded-bottom"</span><span class="nt">&gt;</span>
<span class="nt">&lt;img</span> <span class="na">src=</span><span class="s">"..."</span> <span class="na">alt=</span><span class="s">"..."</span> <span class="na">class=</span><span class="s">"rounded-left"</span><span class="nt">&gt;</span>
<span class="nt">&lt;img</span> <span class="na">src=</span><span class="s">"..."</span> <span class="na">alt=</span><span class="s">"..."</span> <span class="na">class=</span><span class="s">"rounded-circle"</span><span class="nt">&gt;</span>
<span class="nt">&lt;img</span> <span class="na">src=</span><span class="s">"..."</span> <span class="na">alt=</span><span class="s">"..."</span> <span class="na">class=</span><span class="s">"rounded-pill"</span><span class="nt">&gt;</span>
<span class="nt">&lt;img</span> <span class="na">src=</span><span class="s">"..."</span> <span class="na">alt=</span><span class="s">"..."</span> <span class="na">class=</span><span class="s">"rounded-0"</span><span class="nt">&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#basicBorderRadius"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>
<!-- End -->