import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-errorv2',
  templateUrl: './errorv2.component.html',
  styleUrls: ['./errorv2.component.scss']
})
export class Errorv2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
