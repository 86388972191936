<!-- Breadcrumb Area -->
<div class="breadcrumb-area">
    <h1>Dashboard</h1>

    <ol class="breadcrumb">
        <li class="item"><a routerLink="/card/colors-card"><i class='bx bx-home-alt'></i></a></li>

        <li class="item">Dashboard</li>

        <li class="item">Colors Card</li>
    </ol>
</div>
<!-- End Breadcrumb Area -->

<!-- Start -->
<div class="row">
    <div class="col-lg-4 col-md-6">
        <div class="card text-white bg-success mb-30">
            <div class="card-body">
                <h5 class="card-title font-weight-bold text-white">Primary card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card text-white bg-secondary mb-30">
            <div class="card-body">
                <h5 class="card-title font-weight-bold text-white">Secondary card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card text-white bg-success mb-30">
            <div class="card-body">
                <h5 class="card-title font-weight-bold text-white">Success card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card text-white bg-danger mb-30">
            <div class="card-body">
                <h5 class="card-title font-weight-bold text-white">Danger card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card text-white bg-warning mb-30">
            <div class="card-body">
                <h5 class="card-title font-weight-bold text-white">Warning card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card text-white bg-info mb-30">
            <div class="card-body">
                <h5 class="card-title font-weight-bold text-white">Info card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card bg-light mb-30">
            <div class="card-body">
                <h5 class="card-title font-weight-bold">Light card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card text-white bg-dark mb-30">
            <div class="card-body">
                <h5 class="card-title font-weight-bold text-white">Dark card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-4 col-md-6">
        <div class="card p-0 mb-30">
            <div class="card-body p-4 text-primary border-primary">
                <h5 class="card-title font-weight-bold">Primary card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card p-0 mb-30">
            <div class="card-body p-4 text-secondary border-secondary">
                <h5 class="card-title font-weight-bold">Secondary card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card p-0 mb-30">
            <div class="card-body p-4 text-success border-success">
                <h5 class="card-title font-weight-bold">Success card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card p-0 mb-30">
            <div class="card-body p-4 text-danger border-danger">
                <h5 class="card-title font-weight-bold">Danger card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card p-0 mb-30">
            <div class="card-body p-4 text-warning border-warning">
                <h5 class="card-title font-weight-bold">Warning card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card p-0 mb-30">
            <div class="card-body p-4 text-info border-info">
                <h5 class="card-title font-weight-bold">Info card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card p-0 mb-30">
            <div class="card-body p-4 border-light">
                <h5 class="card-title font-weight-bold">Light card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="card p-0 mb-30">
            <div class="card-body p-4 border-dark">
                <h5 class="card-title font-weight-bold">Dark card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
        </div>
    </div>
</div>
<!-- End -->