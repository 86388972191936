<!-- Start Sidemenu Area -->
<div class="sidemenu-area">
    <div class="sidemenu-header">
        <a href="/dashboard/analytics" class="navbar-brand d-flex align-items-center">
            <img src="assets/img/small-logo.png" alt="image">
            <span>Fiva</span>
        </a>

        <div class="burger-menu d-none d-lg-block">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>

        <div class="responsive-burger-menu d-block d-lg-none">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>
    </div>

    <div class="sidemenu-body">
        <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>
            <li class="nav-item-title">
                Main
            </li>

            <li class="nav-item" routerLinkActive="active mm-active">
                <a href="#" class="collapsed-nav-link nav-link" aria-expanded="false">
                    <span class="icon"><i class='bx bx-home-circle'></i></span>
                    <span class="menu-title">Dashboard</span>
                    <span class="badge">2</span>
                </a>

                <ul class="sidemenu-nav-second-level">
                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/dashboard/analytics" class="nav-link">
                            <span class="icon"><i class='bx bx-analyse'></i></span>
                            <span class="menu-title">Analytics</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/dashboard/e-commerce" class="nav-link">
                            <span class="icon"><i class='bx bxs-shopping-bag'></i></span>
                            <span class="menu-title">eCommerce</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="nav-item-title">
                Apps
            </li>

            <li class="nav-item" routerLinkActive="active mm-active">
                <a href="#" class="collapsed-nav-link nav-link" aria-expanded="false">
                    <span class="icon"><i class='bx bx-envelope'></i></span>
                    <span class="menu-title">Email</span>
                    <span class="badge">3</span>
                </a>

                <ul class="sidemenu-nav-second-level">
                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/app-email/inbox" class="nav-link">
                            <span class="icon"><i class='bx bxs-inbox'></i></span>
                            <span class="menu-title">Inbox</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/app-email/read" class="nav-link">
                            <span class="icon"><i class='bx bxs-badge-check'></i></span>
                            <span class="menu-title">Read</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/app-email/compose" class="nav-link">
                            <span class="icon"><i class='bx bx-send'></i></span>
                            <span class="menu-title">Compose</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/app-chat" class="nav-link">
                    <span class="icon"><i class='bx bx-message'></i></span>
                    <span class="menu-title">Chat</span>
                </a>
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/app-todo" class="nav-link">
                    <span class="icon"><i class='bx bx-badge-check'></i></span>
                    <span class="menu-title">Todo</span>
                </a>
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/app-calendar" class="nav-link">
                    <span class="icon"><i class='bx bx-calendar'></i></span>
                    <span class="menu-title">Calendar</span>
                </a>
            </li>

            <li class="nav-item-title">
                UI Elements
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/grid" class="nav-link">
                    <span class="icon"><i class='bx bx-grid-alt'></i></span>
                    <span class="menu-title">Grid</span>
                </a>
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/colors" class="nav-link">
                    <span class="icon"><i class='bx bxs-color-fill'></i></span>
                    <span class="menu-title">Colors</span>
                </a>
            </li>

            <li class="nav-item" routerLinkActive="active mm-active">
                <a href="#" class="collapsed-nav-link nav-link" aria-expanded="false">
                    <span class="icon"><i class='bx bx-brightness'></i></span>
                    <span class="menu-title">Icons</span>
                </a>

                <ul class="sidemenu-nav-second-level">
                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/icons/boxicons" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">BoxIcons</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/icons/feathericons" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Feather Icons</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="nav-item" routerLinkActive="active mm-active">
                <a href="#" class="collapsed-nav-link nav-link" aria-expanded="false">
                    <span class="icon"><i class='bx bx-card'></i></span>
                    <span class="menu-title">Card</span>
                </a>

                <ul class="sidemenu-nav-second-level">
                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/card/basic-card" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Basic Card</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/card/colors-card" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Colors Card</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/card/statistics-card" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Statistics Card</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="nav-item" routerLinkActive="active mm-active">
                <a href="#" class="collapsed-nav-link nav-link" aria-expanded="false">
                    <span class="icon"><i class='bx bx-chart'></i></span>
                    <span class="menu-title">Component</span>
                </a>

                <ul class="sidemenu-nav-second-level">
                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/alerts" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Alert</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/badges" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Badge</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/borders" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Border</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/breadcrumb" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Breadcrumb</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/buttons" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Buttons</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/buttons-group" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Button Group</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/slider" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Slider</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/collapse" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Collapse</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/display" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Display</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/dropdown" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Dropdown</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/embed" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Embed</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/figures" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Figures</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/images" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Images</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/jumbotron" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Jumbotron</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/list-group" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">List Group</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/media-object" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Media Object</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/modal" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Modal</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/navs" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Navs</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/navbar" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Navbar</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/pagination" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Pagination</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/progress" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Progress</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/spinner" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Spinner</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/text" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Text</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/vertical-alignment" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Vertical Alignment</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/typography" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Typography</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/tooltips" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Tooltips</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/ui-components/popovers" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Popovers</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="nav-item-title">
                Forms & Table
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/forms/form-layout" class="nav-link">
                    <span class="icon"><i class='bx bxs-layer'></i></span>
                    <span class="menu-title">Form Layout</span>
                </a>
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/forms/form-input-group" class="nav-link">
                    <span class="icon"><i class='bx bx-file'></i></span>
                    <span class="menu-title">Form Input Group</span>
                </a>
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/table/table-light" class="nav-link">
                    <span class="icon"><i class='bx bx-table'></i></span>
                    <span class="menu-title">Table</span>
                </a>
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/table/table-dark" class="nav-link">
                    <span class="icon"><i class='bx bx-calendar'></i></span>
                    <span class="menu-title">Table Dark</span>
                </a>
            </li>

            <li class="nav-item-title">
                Pages
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/profile" class="nav-link">
                    <span class="icon"><i class='bx bx-user-circle'></i></span>
                    <span class="menu-title">Profile</span>
                </a>
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/faq" class="nav-link">
                    <span class="icon"><i class='bx bx-help-circle'></i></span>
                    <span class="menu-title">FAQ</span>
                </a>
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/invoice" class="nav-link">
                    <span class="icon"><i class='bx bx-receipt'></i></span>
                    <span class="menu-title">Invoice</span>
                </a>
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/gallery" class="nav-link">
                    <span class="icon"><i class='bx bx-images'></i></span>
                    <span class="menu-title">Gallery</span>
                </a>
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/timeline" class="nav-link">
                    <span class="icon"><i class='bx bx-align-justify'></i></span>
                    <span class="menu-title">Timeline</span>
                </a>
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/pricing" class="nav-link">
                    <span class="icon"><i class='bx bx-money'></i></span>
                    <span class="menu-title">Pricing</span>
                </a>
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/blank-page" class="nav-link">
                    <span class="icon"><i class='bx bx-file-blank'></i></span>
                    <span class="menu-title">Blank Page</span>
                </a>
            </li>
            
            <li class="nav-item" routerLinkActive="active mm-active">
                <a href="#" class="collapsed-nav-link nav-link" aria-expanded="false">
                    <span class="icon"><i class='bx bx-lock-open'></i></span>
                    <span class="menu-title">Authentication</span>
                </a>

                <ul class="sidemenu-nav-second-level">
                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/authentication/login" target="_blank" class="nav-link">
                            <span class="icon"><i class='bx bx-log-in'></i></span>
                            <span class="menu-title">Login v1</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/authentication/login-with-image" target="_blank" class="nav-link">
                            <span class="icon"><i class='bx bxs-log-in'></i></span>
                            <span class="menu-title">Login v2</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/authentication/register" target="_blank" class="nav-link">
                            <span class="icon"><i class='bx bx-log-in-circle'></i></span>
                            <span class="menu-title">Register v1</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/authentication/register-with-image" target="_blank" class="nav-link">
                            <span class="icon"><i class='bx bxs-log-in-circle'></i></span>
                            <span class="menu-title">Register v2</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/authentication/forgot-password" target="_blank" class="nav-link">
                            <span class="icon"><i class='bx bx-lock'></i></span>
                            <span class="menu-title">Forgot Password v1</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/authentication/forgot-password-with-image" target="_blank" class="nav-link">
                            <span class="icon"><i class='bx bx-lock-alt'></i></span>
                            <span class="menu-title">Forgot Password v2</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/authentication/reset-password" target="_blank" class="nav-link">
                            <span class="icon"><i class='bx bx-log-out'></i></span>
                            <span class="menu-title">Reset Password v1</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/authentication/reset-password-with-image" target="_blank" class="nav-link">
                            <span class="icon"><i class='bx bx-log-out-circle'></i></span>
                            <span class="menu-title">Reset Password v2</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/authentication/lock-screen" target="_blank" class="nav-link">
                            <span class="icon"><i class='bx bxs-lock-open-alt'></i></span>
                            <span class="menu-title">Lock Screen v1</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/authentication/lock-screen-with-image" target="_blank" class="nav-link">
                            <span class="icon"><i class='bx bxs-lock-open'></i></span>
                            <span class="menu-title">Lock Screen v2</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="nav-item" routerLinkActive="active mm-active">
                <a href="#" class="collapsed-nav-link nav-link" aria-expanded="false">
                    <span class="icon"><i class='bx bx-share-alt'></i></span>
                    <span class="menu-title">Miscellaneous</span>
                </a>

                <ul class="sidemenu-nav-second-level">
                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/miscellaneous/not-authorized" target="_blank" class="nav-link">
                            <span class="icon"><i class='bx bx-traffic-cone'></i></span>
                            <span class="menu-title">Not Authorized v1</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/miscellaneous/not-authorized-with-image" target="_blank" class="nav-link">
                            <span class="icon"><i class='bx bxs-traffic-cone'></i></span>
                            <span class="menu-title">Not Authorized v2</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/miscellaneous/maintenance" target="_blank" class="nav-link">
                            <span class="icon"><i class='bx bx-recycle'></i></span>
                            <span class="menu-title">Maintenance v1</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/miscellaneous/maintenance-with-image" target="_blank" class="nav-link">
                            <span class="icon"><i class='bx bx-recycle bx-rotate-90'></i></span>
                            <span class="menu-title">Maintenance v2</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/miscellaneous/coming-soon" target="_blank" class="nav-link">
                            <span class="icon"><i class='bx bxs-up-arrow'></i></span>
                            <span class="menu-title">Coming Soon v1</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/miscellaneous/coming-soon-with-image" target="_blank" class="nav-link">
                            <span class="icon"><i class='bx bxs-right-arrow'></i></span>
                            <span class="menu-title">Coming Soon v2</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active mm-active">
                        <a href="#" class="collapsed-nav-link nav-link" aria-expanded="false">
                            <span class="icon"><i class='bx bx-share-alt'></i></span>
                            <span class="menu-title">Error</span>
                        </a>

                        <ul class="sidemenu-nav-third-level">
                            <li class="nav-item" routerLinkActive="active">
                                <a routerLink="/error/errorv1" target="_blank" class="nav-link">
                                    <span class="icon"><i class='bx bx-error'></i></span>
                                    <span class="menu-title">404 v1</span>
                                </a>
                            </li>

                            <li class="nav-item" routerLinkActive="active">
                                <a routerLink="/error/errorv2" target="_blank" class="nav-link">
                                    <span class="icon"><i class='bx bxs-error'></i></span>
                                    <span class="menu-title">404 v2</span>
                                </a>
                            </li>

                            <li class="nav-item" routerLinkActive="active">
                                <a routerLink="/error/errorv3" target="_blank" class="nav-link">
                                    <span class="icon"><i class='bx bx-error-circle'></i></span>
                                    <span class="menu-title">500 v1</span>
                                </a>
                            </li>

                            <li class="nav-item" routerLinkActive="active">
                                <a routerLink="/error/errorv4" target="_blank" class="nav-link">
                                    <span class="icon"><i class='bx bxs-error-circle'></i></span>
                                    <span class="menu-title">500 v2</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>

            <li class="nav-item-title">
                Charts & Maps
            </li>

            <li class="nav-item" routerLinkActive="active mm-active">
                <a href="#" class="collapsed-nav-link nav-link" aria-expanded="false">
                    <span class="icon"><i class='bx bx-chart'></i></span>
                    <span class="menu-title">Charts</span>
                </a>

                <ul class="sidemenu-nav-second-level">
                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/apex-charts" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Apex Charts</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active">
                        <a routerLink="/chartjs" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Chartjs</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/maps" class="nav-link">
                    <span class="icon"><i class='bx bx-world'></i></span>
                    <span class="menu-title">Maps</span>
                </a>
            </li>

            <li class="nav-item-title">
                Others
            </li>

            <li class="nav-item">
                <a href="#" class="collapsed-nav-link nav-link" aria-expanded="false">
                    <span class="icon"><i class='bx bx-menu'></i></span>
                    <span class="menu-title">Menu Levels</span>
                </a>

                <ul class="sidemenu-nav-second-level">
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">First Level</span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a href="javascript:void(0)" class="collapsed-nav-link nav-link" aria-expanded="false">
                            <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                            <span class="menu-title">Second Level</span>
                        </a>

                        <ul class="sidemenu-nav-third-level">
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">
                                    <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                                    <span class="menu-title">Second Level 2.1</span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">
                                    <span class="icon"><i class='bx bx-right-arrow-alt'></i></span>
                                    <span class="menu-title">Second Level 2.2</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>

            <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link disabled">
                    <span class="icon"><i class='bx bx-unlink'></i></span>
                    <span class="menu-title">Disable Menu</span>
                </a>
            </li>

            <li class="nav-item-title">
                Support
            </li>

            <li class="nav-item">
                <a href="#" class="nav-link">
                    <span class="icon"><i class='bx bx-file'></i></span>
                    <span class="menu-title">Documentation</span>
                </a>
            </li>

            <li class="nav-item">
                <a href="mailto:hello@envytheme.com" class="nav-link">
                    <span class="icon"><i class='bx bx-support'></i></span>
                    <span class="menu-title">Support</span>
                </a>
            </li>
        </ul>
    </div>
</div>
<!-- End Sidemenu Area -->