import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-app-todo",
    templateUrl: "./app-todo.component.html",
    styleUrls: ["./app-todo.component.scss"],
})
export class AppTodoComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
