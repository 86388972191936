import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email',
  templateUrl: './app-email.component.html',
  styleUrls: ['./app-email.component.scss']
})
export class AppEmailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
