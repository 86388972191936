<!-- Breadcrumb Area -->
<div class="breadcrumb-area">
    <h1>Dashboard</h1>

    <ol class="breadcrumb">
        <li class="item"><a routerLink="/ui-components/popovers"><i class='bx bx-home-alt'></i></a></li>

        <li class="item">Dashboard</li>

        <li class="item">Popovers</li>
    </ol>
</div>
<!-- End Breadcrumb Area -->

<!-- Start -->
<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Basic Popovers</h3>
    </div>

    <div class="card-body">
        <button type="button" class="btn btn-lg btn-danger" data-bs-toggle="popover" title="Popover title" data-bs-content="And here's some amazing content. It's very engaging. Right?">Click to toggle popover</button>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="basicPopovers"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-lg btn-danger"</span> <span class="na">data-bs-toggle=</span><span class="s">"popover"</span> <span class="na">title=</span><span class="s">"Popover title"</span> <span class="na">data-bs-content=</span><span class="s">"And here's some amazing content. 
It's very engaging. Right?"</span><span class="nt">&gt;</span>Click to toggle popover<span class="nt">&lt;/button&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#basicPopovers"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30 popovers-card-box">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Four Directions</h3>
    </div>

    <div class="card-body">
        <button type="button" class="btn btn-primary mt-2 mr-2" data-container="body" data-bs-toggle="popover" data-placement="top" data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
            Popover on top
        </button>

        <button type="button" class="btn btn-secondary mt-2 mr-2" data-container="body" data-bs-toggle="popover" data-placement="right" data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
            Popover on right
        </button>

        <button type="button" class="btn btn-success mt-2 mr-2" data-container="body" data-bs-toggle="popover" data-placement="bottom" data-bs-content="Vivamus
        sagittis lacus vel augue laoreet rutrum faucibus.">
            Popover on bottom
        </button>

        <button type="button" class="btn btn-dark mt-2" data-container="body" data-bs-toggle="popover" data-placement="left" data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
            Popover on left
        </button>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="fourDirectionsPopovers"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-primary"</span> <span class="na">data-container=</span><span class="s">"body"</span> <span class="na">data-bs-toggle=</span><span class="s">"popover"</span> <span class="na">data-placement=</span><span class="s">"top"</span> <span class="na">data-bs-content=</span><span class="s">"Vivamus sagittis 
lacus vel augue laoreet rutrum faucibus."</span><span class="nt">&gt;</span>
    Popover on top
<span class="nt">&lt;/button&gt;</span>

<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-secondary"</span> <span class="na">data-container=</span><span class="s">"body"</span> <span class="na">data-bs-toggle=</span><span class="s">"popover"</span> <span class="na">data-placement=</span><span class="s">"right"</span> <span class="na">data-bs-content=</span><span class="s">"Vivamus sagittis 
lacus vel augue laoreet rutrum faucibus."</span><span class="nt">&gt;</span>
    Popover on right
<span class="nt">&lt;/button&gt;</span>

<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-success"</span> <span class="na">data-container=</span><span class="s">"body"</span> <span class="na">data-bs-toggle=</span><span class="s">"popover"</span> <span class="na">data-placement=</span><span class="s">"bottom"</span> <span class="na">data-bs-content=</span><span class="s">"Vivamus
sagittis lacus vel augue laoreet rutrum faucibus."</span><span class="nt">&gt;</span>
    Popover on bottom
<span class="nt">&lt;/button&gt;</span>

<span class="nt">&lt;button</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">class=</span><span class="s">"btn btn-dark"</span> <span class="na">data-container=</span><span class="s">"body"</span> <span class="na">data-bs-toggle=</span><span class="s">"popover"</span> <span class="na">data-placement=</span><span class="s">"left"</span> <span class="na">data-bs-content=</span><span class="s">"Vivamus sagittis 
lacus vel augue laoreet rutrum faucibus."</span><span class="nt">&gt;</span>
    Popover on left
<span class="nt">&lt;/button&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#fourDirectionsPopovers"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Dismiss on Next Click</h3>
    </div>

    <div class="card-body">
        <a tabindex="0" class="btn btn-lg btn-danger" role="button" data-bs-toggle="popover" data-trigger="focus" title="Dismissible popover" data-bs-content="And here's some amazing content. It's very engaging. Right?">Dismissible popover</a>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="dismissPopovers"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;a</span> <span class="na">tabindex=</span><span class="s">"0"</span> <span class="na">class=</span><span class="s">"btn btn-lg btn-danger"</span> <span class="na">role=</span><span class="s">"button"</span> <span class="na">data-bs-toggle=</span><span class="s">"popover"</span> <span class="na">data-trigger=</span><span class="s">"focus"</span> <span class="na">title=</span><span class="s">"Dismissible popover"</span> <span class="na">data-bs-content=</span>
<span class="s">"And here's some amazing content. It's very engaging. Right?"</span><span class="nt">&gt;</span>Dismissible popover<span class="nt">&lt;/a&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#dismissPopovers"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>

<div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Disabled Elements</h3>
    </div>

    <div class="card-body">
        <span class="d-inline-block" data-bs-toggle="popover" data-bs-content="Disabled popover">
            <button class="btn btn-primary" style="pointer-events: none;" type="button" disabled>Disabled button</button>
        </span>

        <div class="demo-code-preview" data-label="Code Example">
            <div class="highlight" id="disabledPopovers"><pre><code class="language-html" data-lang="html"><span class="nt">&lt;span</span> <span class="na">class=</span><span class="s">"d-inline-block"</span> <span class="na">data-bs-toggle=</span><span class="s">"popover"</span> <span class="na">data-bs-content=</span><span class="s">"Disabled popover"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;button</span> <span class="na">class=</span><span class="s">"btn btn-primary"</span> <span class="na">style=</span><span class="s">"pointer-events: none;"</span> <span class="na">type=</span><span class="s">"button"</span> <span class="na">disabled</span><span class="nt">&gt;</span>Disabled button<span class="nt">&lt;/button&gt;</span>
<span class="nt">&lt;/span&gt;</span></code></pre>

                <button type="button" class="btn btn-clipboard" data-clipboard-target="#disabledPopovers"><i class='bx bx-copy'></i></button>
            </div>
        </div>
    </div>
</div>
<!-- End -->