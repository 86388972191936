<!-- Breadcrumb Area -->
<div class="breadcrumb-area">
    <h1>Dashboard</h1>

    <ol class="breadcrumb">
        <li class="item"><a routerLink="/ui-components/embed"><i class='bx bx-home-alt'></i></a></li>

        <li class="item">Dashboard</li>

        <li class="item">Embed</li>
    </ol>
</div>
<!-- End Breadcrumb Area -->

<!-- Start -->
<div class="row">
    <div class="col-lg-6">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Aspect Ratios(21by9)</h3>
            </div>

            <div class="card-body">
                <div class="embed-responsive embed-responsive-21by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/h4pxLHG0Wzs" allowfullscreen></iframe>
                </div>

                <div class="demo-code-preview" data-label="Code Example">
                    <div class="highlight" id="basicEmbedLink1"><pre><code class="language-markup" data-lang="html"><span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"embed-responsive embed-responsive-21by9"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;iframe</span> <span class="na">class=</span><span class="s">"embed-responsive-item"</span> <span class="na">src=</span><span class="s">"https://www.youtube.com/embed/h4pxLHG0Wzs"</span> <span class="na">allowfullscreen</span><span class="nt">&gt;&lt;/iframe&gt;</span>
<span class="nt">&lt;/div&gt;</span></code></pre>

                        <button type="button" class="btn btn-clipboard" data-clipboard-target="#basicEmbedLink1"><i class='bx bx-copy'></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Aspect Ratios(16by9)</h3>
            </div>

            <div class="card-body">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/bk7McNUjWgw" allowfullscreen></iframe>
                </div>

                <div class="demo-code-preview" data-label="Code Example">
                    <div class="highlight" id="basicEmbedLink2"><pre><code class="language-markup" data-lang="html"><span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"embed-responsive embed-responsive-16by9"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;iframe</span> <span class="na">class=</span><span class="s">"embed-responsive-item"</span> <span class="na">src=</span><span class="s">"https://www.youtube.com/embed/bk7McNUjWgw"</span> <span class="na">allowfullscreen</span><span class="nt">&gt;&lt;/iframe&gt;</span>
<span class="nt">&lt;/div&gt;</span></code></pre>

                        <button type="button" class="btn btn-clipboard" data-clipboard-target="#basicEmbedLink2"><i class='bx bx-copy'></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Aspect Ratios(4by3)</h3>
            </div>

            <div class="card-body">
                <div class="embed-responsive embed-responsive-4by3">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/OD6cQ2dh7Mo" allowfullscreen></iframe>
                </div>

                <div class="demo-code-preview" data-label="Code Example">
                    <div class="highlight" id="basicEmbedLink3"><pre><code class="language-markup" data-lang="html"><span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"embed-responsive embed-responsive-4by3"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;iframe</span> <span class="na">class=</span><span class="s">"embed-responsive-item"</span> <span class="na">src=</span><span class="s">"https://www.youtube.com/embed/OD6cQ2dh7Mo"</span> <span class="na">allowfullscreen</span><span class="nt">&gt;&lt;/iframe&gt;</span>
<span class="nt">&lt;/div&gt;</span></code></pre>

                        <button type="button" class="btn btn-clipboard" data-clipboard-target="#basicEmbedLink3"><i class='bx bx-copy'></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Aspect Ratios(1by1)</h3>
            </div>

            <div class="card-body">
                <div class="embed-responsive embed-responsive-1by1">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/F2ethtmiFyQ" allowfullscreen></iframe>
                </div>

                <div class="demo-code-preview" data-label="Code Example">
                    <div class="highlight" id="basicEmbedLink4"><pre><code class="language-markup" data-lang="html"><span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">"embed-responsive embed-responsive-1by1"</span><span class="nt">&gt;</span>
    <span class="nt">&lt;iframe</span> <span class="na">class=</span><span class="s">"embed-responsive-item"</span> <span class="na">src=</span><span class="s">"https://www.youtube.com/embed/F2ethtmiFyQ"</span> <span class="na">allowfullscreen</span><span class="nt">&gt;&lt;/iframe&gt;</span>
<span class="nt">&lt;/div&gt;</span></code></pre>

                        <button type="button" class="btn btn-clipboard" data-clipboard-target="#basicEmbedLink4"><i class='bx bx-copy'></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End -->