<!-- Breadcrumb Area -->
<div class="breadcrumb-area">
    <h1>Dashboard</h1>

    <ol class="breadcrumb">
        <li class="item"><a routerLink="/pricing"><i class='bx bx-home-alt'></i></a></li>

        <li class="item">Dashboard</li>

        <li class="item">Pricing</li>
    </ol>
</div>
<!-- End Breadcrumb Area -->

<!-- Start Pricing Area -->
<section class="pricing-area ptb-100">
    <div class="row">
        <div class="col-lg-4 col-md-6">
            <div class="single-pricing-box">
                <div class="pricing-header">
                    <h3>Basic</h3>
                </div>

                <div class="price">
                    <sub>$</sub>
                    39.99
                    <sub>/mo</sub>
                </div>

                <ul class="price-features-list">
                    <li><i class='bx bx-check-double'></i> 1 Projects</li>
                    <li><i class='bx bx-check-double'></i> Email Support</li>
                    <li><i class='bx bx-check-double'></i> Phone Support</li>
                    <li><i class='bx bx-check-double'></i> Article Promotion</li>
                    <li><i class='bx bx-check-double'></i> Editorial Services</li>
                    <li><i class='bx bx-x'></i> Profile Management</li>
                    <li><i class='bx bx-x'></i> Selection Support</li>
                </ul>

                <a href="javascript:void(0)" class="get-started-btn">Get Started</a>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-pricing-box red">
                <div class="pricing-header">
                    <h3>Starter</h3>
                </div>

                <div class="price">
                    <sub>$</sub>
                    49.99
                    <sub>/mo</sub>
                </div>

                <ul class="price-features-list">
                    <li><i class='bx bx-check-double'></i> 2 Projects</li>
                    <li><i class='bx bx-check-double'></i> Email Support</li>
                    <li><i class='bx bx-check-double'></i> Phone Support</li>
                    <li><i class='bx bx-check-double'></i> Article Promotion</li>
                    <li><i class='bx bx-check-double'></i> Editorial Services</li>
                    <li><i class='bx bx-check-double'></i> Profile Management</li>
                    <li><i class='bx bx-x'></i> Selection Support</li>
                </ul>

                <a href="javascript:void(0)" class="get-started-btn">Get Started</a>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
            <div class="single-pricing-box orange">
                <div class="pricing-header">
                    <h3>Extended</h3>
                </div>

                <div class="price">
                    <sub>$</sub>
                    59.99
                    <sub>/mo</sub>
                </div>

                <ul class="price-features-list">
                    <li><i class='bx bx-check-double'></i> 3 Projects</li>
                    <li><i class='bx bx-check-double'></i> Email Support</li>
                    <li><i class='bx bx-check-double'></i> Phone Support</li>
                    <li><i class='bx bx-check-double'></i> Article Promotion</li>
                    <li><i class='bx bx-check-double'></i> Editorial Services</li>
                    <li><i class='bx bx-check-double'></i> Profile Management</li>
                    <li><i class='bx bx-check-double'></i> Selection Support</li>
                </ul>

                <a href="javascript:void(0)" class="get-started-btn">Get Started</a>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing Area -->